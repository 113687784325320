import '@assets/css/style.css';
import { notify } from '@common/general-ui/notify/notify.component';
import { AmplifyEx } from '@common/hooks/use-cognito.hook';
import { ModalProviderEx } from '@common/hooks/use-modal-ex.hook';
import { t } from '@common/hooks/use-t.hook';
import { IBEError } from '@common/interfaces/be-error.interface';
import { authService } from '@common/services/auth.service';
import { consoleService } from '@common/services/console.service';
import { customNotify, IErrorItem, ISuccessItem } from '@common/utils/custom-notify.util';
import React, { Suspense } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { css, GlobalStyles } from 'twin.macro';
import { ERROR_CODES_MAP } from './api/error-codes-map.constant';
import { awsConfig } from './aws-exports';
import { queryClient } from './shared/query-client';
import { RenderRootRoutes } from './shared/render-root-routes';
import { ConvertSnippet } from './shared/utils/convert-ab-test-snippet.util';

localStorage.setItem('REACT_APP_GIT_COMMIT_HASH', process.env.REACT_APP_GIT_COMMIT_HASH || '');
// eslint-disable-next-line no-console
console.log(
    `env=${process.env.NODE_ENV} env-file=${process.env.REACT_APP_ENV_FILE} commit-id=${
        process.env.REACT_APP_GIT_COMMIT_HASH || ''
    }`,
);
localStorage.setItem('REACT_APP_NODE_ENV', process.env.REACT_APP_NODE_ENV || '');
consoleService.log('cognito-config', awsConfig);
AmplifyEx.configure(awsConfig);

customNotify.init({
    successHandler: (item: ISuccessItem): void => {
        notify({ type: 'success', title: item.title, description: item.description, onShown: item.onShown });
    },
    errorHandler: (e: IErrorItem): void => {
        notify({
            type: 'error',
            title: e.title || t('Oopssss!'),
            description:
                e.description ||
                (e.beError as IBEError | undefined)?.clientDescription ||
                ERROR_CODES_MAP[(e.beError as IBEError | undefined)?.error as unknown as string] ||
                '',
        });
    },
});
authService.init({ qc: queryClient, cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN || '', type: 'cookie' });
const App: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            <Suspense fallback={null}>
                {/* Add convert AB test script */}
                <ConvertSnippet />

                <BrowserRouter basename="/">
                    <ModalProviderEx>
                        {/* <CognitoIframeComponent messageForIframe={state}  />
                        <button tw="mt-20 absolute z-10" onClick={(): void => setState(ECognitoOperation.loginWithGoogle)}>
                            login g
						</button>
						<button tw="mt-24 absolute z-10" onClick={(): void => setState(ECognitoOperation.loginWithFacebook)}>
                            login fb
                        </button> */}
                        <RenderRootRoutes />
                    </ModalProviderEx>
                </BrowserRouter>
            </Suspense>
            <div css={styles.root}>
                <ReactQueryDevtools initialIsOpen={false} />
            </div>
        </QueryClientProvider>
    );
};

export default App;

const styles = {
    root: css`
        .ReactQueryDevtoolsPanel {
            // background-color: #666 !important;
            font-size: 18px !important;
            & pre {
                line-height: 18px;
            }
            & div {
                line-height: 18px;
            }
            > div {
                border: 1px solid white;
                max-width: 30% !important;
            }
            > div:first-of-type {
                max-width: 100% !important;
            }
            > div:last-of-type {
                max-width: 100% !important;
            }
        }
    `,
};
